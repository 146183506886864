/* This file is for your main application css. */
html {
  font-family: 'Open Sans', sans-serif;
  scroll-behavior: smooth; }

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6,
.uk-heading-2xlarge, .uk-heading-xlarge,
.uk-heading-large, .uk-heading-medium, .uk-heading-small,
h1, h2, h3, h4, h5, h6 {
  font-family: 'Public Sans', sans-serif; }

.uk-button-default {
  background-color: white; }

.tm-button-primary {
  background-color: rgba(0, 0, 128, 0.93);
  color: #fff;
  font-weight: 700;
  border: 1px solid transparent; }
  .tm-button-primary:focus, .tm-button-primary:hover {
    background-color: navy;
    color: #fff; }

.tm-section-nav {
  background-color: navy; }
  .tm-section-nav .uk-navbar-container {
    background-color: inherit; }
    .tm-section-nav .uk-navbar-container button, .tm-section-nav .uk-navbar-container .uk-logo {
      font-family: 'Public Sans', sans-serif;
      color: #fff; }
    .tm-section-nav .uk-navbar-container .uk-navbar-nav > li > a {
      font-family: 'Public Sans', sans-serif;
      color: #fff;
      font-weight: 600; }
  .tm-section-nav #offcanvas-nav .uk-offcanvas-bar {
    background-color: navy; }
    .tm-section-nav #offcanvas-nav .uk-offcanvas-bar .uk-nav.uk-nav-primary > li > a {
      font-family: 'Public Sans', sans-serif;
      color: #fff;
      font-weight: 500; }

.tm-text-xsmall {
  font-size: .625rem;
  line-height: 1.5; }

.tm-text-meta {
  font-size: .875rem;
  line-height: 1.4;
  color: navy; }

.tm-featured-lead {
  font-family: 'Public Sans', sans-serif;
  font-size: 7vw;
  font-weight: 700;
  letter-spacing: 0.15rem;
  line-height: 1.35;
  color: #fff000;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.35); }

/* Tablet Landscape and bigger */
@media (min-width: 960px) {
  .tm-featured-lead {
    font-size: 4vw; } }

.uk-dotnav > * > :hover {
  color: navy;
  background-color: navy; }

.uk-dotnav > * > * {
  color: navy;
  border: 1px solid navy; }

.uk-dotnav > .uk-active > * {
  color: white;
  background-color: navy; }

.tm-input {
  position: relative; }
  .tm-input .uk-form-label {
    font-weight: 500; }

.uk-card-default.tm-card-red {
  background-color: #730b0b;
  border-radius: 4px;
  color: white !important;
  font-weight: 600; }
  .uk-card-default.tm-card-red h5, .uk-card-default.tm-card-red .uk-card-title {
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35); }
  .uk-card-default.tm-card-red:hover {
    background-color: #730b0b; }

.uk-card-default.tm-card-green {
  background-color: #0b730b;
  border-radius: 4px;
  color: white !important;
  font-weight: 600; }
  .uk-card-default.tm-card-green h5, .uk-card-default.tm-card-green .uk-card-title {
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35); }
  .uk-card-default.tm-card-green:hover {
    background-color: #0b730b; }

.uk-card-default.tm-card-blue {
  background-color: #0b0b73;
  border-radius: 4px;
  color: white !important;
  font-weight: 600; }
  .uk-card-default.tm-card-blue h5, .uk-card-default.tm-card-blue .uk-card-title {
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35); }
  .uk-card-default.tm-card-blue:hover {
    background-color: #0b0b73; }

.tm-border-nav > li > a {
  padding-left: 10px;
  border-left: 2px solid transparent; }
  .tm-border-nav > li > a.up-current {
    border-left: 2px solid navy;
    color: navy; }

.tm-section-article table {
  margin-bottom: 20px; }
  .tm-section-article table td {
    padding: 0 10px; }

.tm-section-article h4, .tm-section-article h5 {
  color: navy; }

.tm-text-strike-through {
  text-decoration: line-through; }

.tm-text-red {
  color: #730b0b; }

.tm-text-green {
  color: #0b730b; }

.tm-text-blue {
  color: #0b0b73; }
